import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";


import CustomSwitch from "../../components/button/CustomSwitch";
import CookieSettingPopup from "../../components/popup/CookieSettingPopup";
import TopBanner from "../../components/layout/TopBanner";
import { SearchId } from "../../services/SearchId";
// import { tempLineId1 } from "../../services/Api";
import {
  postUpdatePet,
  SearchPetDetail,
  SearchPetOwnerProfile,
} from "../../services/Dogbook";
import getTime from "../../functions/getTime";
import { useLiff } from "react-liff";
import MemberCard from "../../components/card/MemberCard";
import TopLogo from "../../components/layout/TopLogo";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import DogPaw from "../../assets/images/icon/DogPaw";
import IconExport from "../../components/IconExport";
const { edit, book, alarm, petFace, add } = IconExport

console.log('edit >>', edit)
export default function DogBook() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //ช้อมูลผู้ใช้ - info/ สมุดที่อยู่ - address
  let [page, setPage] = useState("info");
  let [ownerProfile, setOwnerProfile] = useState(null);
  let [pet, setPet] = useState(null);

  // popup
  let [openCookiePopup, setOpenCookiePopup] = useState(false);
  let [openTooltip, setOpenTooltip] = useState(true);

  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  useEffect(() => {
    getSearchId();
    // eslint-disable-next-line
  }, []);

  async function getSearchId() {
    return await SearchId({
      params: { lineId: lineId || null },
    })
      .then(async (response) => {
        if (!response.data.body.memberId) {
          navigate("/register");
        } else {
          getOwnerProfile(response.data.body.memberId);
          getPet(response.data.body.memberId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getOwnerProfile(member_id) {
    return await SearchPetOwnerProfile({
      params: { lineId: lineId || null },
      member_id: member_id,
    })
      .then(async (response) => {
        setOwnerProfile(response.data.body);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getPet(member_id) {
    return await SearchPetDetail({
      params: { lineId: lineId || null },
      member_id: member_id,
    })
      .then(async (response) => {
        if (response.data.resultCode === 20000) {
          if (response.data.body.length > 0) {
            setPet(response.data.body);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function postUpdatePetNoti(petId, isNoti) {
    const payload = { petId: petId, isNoti: isNoti };
    try {
      const { data } = await postUpdatePet({ data: payload });
      console.log("resData.data >>", data);
      if (data.resultCode === 20000) {
        console.log("resData.data >>", data.body);
        getSearchId();
      }
    } catch (error) {
      console.log("createPetOwnerRegister error", error);
    }
  }

  function UserInfo(title, data) {
    return (
      <div className="flex justify-between items-start">
        <p className="bText-gray text-[20px] whitespace-pre-line leading-[24px]">
          {title}:
        </p>
        {data ? (
          <p className="max-w-[200px] text-right text-darkGray text-[20px] whitespace-pre-line leading-[24px]">
            {data}
          </p>
        ) : (
          <p className="text-darkGray text-[20px]">-</p>
        )}
      </div>
    );
  }

  const PetCarousal = () => {
    return (
      <Swiper
        spaceBetween={13}
        // slidesPerView={1.08}
        pagination={true}
        modules={[Pagination]}
        scrollbar={{ draggable: true }}
      >
        {pet &&
          pet.map((item, i) => (
            <SwiperSlide key={i}>
              <div className="bg-white rounded-[4px] relative pt-[68px] mt-[55px]">
                {item.urlPicture ? (
                  <img
                    className="absolute top-[-50px] left-0 right-0 mx-auto rounded-full w-[118px] h-[118px] object-cover"
                    src={
                      item.urlPicture.startsWith("data:image/png;base64")
                        ? item.urlPicture
                        : `data:image/png;base64,${item.urlPicture}`
                    }
                    alt=""
                  />
                ) : (
                  <div className="absolute top-[-50px] left-0 right-0 mx-auto rounded-full h-[118px] w-[118px] bg-blueGray flex justify-center items-center">
                    <img className="w-[60px]" src={petFace} alt="" />
                  </div>
                )}

                <div className="p-[16px]">
                  <p className="text-[24px] text-lightBlue">ข้อมูลน้องแมว</p>
                  <div className="flex flex-col gap-[8px] mt-[10px]">
                    {UserInfo("ชื่อน้องแมว", item.petName)}
                    {item.dogBreed ? (
                      <span>{UserInfo("สายพันธุ์", item.dogBreed.name)}</span>
                    ) : (
                      <span>{UserInfo("สายพันธุ์", "-")}</span>
                    )}
                    {UserInfo(
                      "วันเกิด",
                      getTime.getFullTimeString(item.birthday)
                    )}
                    {UserInfo("อายุ", `${getTime.getYearRange(item.birthday)}`)}
                    {UserInfo(
                      "น้ำหนัก",
                      `${parseInt(item.weight).toFixed(0)} กิโลกรัม`
                    )}
                  </div>

                  <div className="flex items-center gap-[12px]">
                    <button
                      onClick={() => navigate(`/dogbook-edit/${item.petId}`)}
                      className="mt-[20px] w-full flex gap-[5px] items-center justify-center py-[2px] px-[24px] rounded-[28px] border-[1px] border-blue"
                    >
                      <img className="w-[14px]" src={edit} alt="" />
                      <p className="bText-primary-d text-[20px] whitespace-nowrap">
                        แก้ไขข้อมูล
                      </p>
                    </button>
                    <button
                      onClick={() => navigate(`/pet-diary?petId=${item.petId}`)}
                      className="bg-blue mt-[20px] w-full flex gap-[5px] items-center justify-center py-[2px] px-[24px] rounded-[28px] border-[1px] border-blue"
                    >
                      <img className="w-[14px]" src={book} alt="" />
                      <p className="text-white text-[20px]">บันทึกสุขภาพ</p>
                    </button>
                  </div>

                  <hr className="border-b-1px border-lightGray w-full my-[20px]" />

                  <div>
                    <p className="bText-gray text-[20px]">
                      ตั้งเวลาแจ้งเตือนพบคุณหมอ:
                    </p>

                    <div className="flex items-center justify-between">
                      <div className="flex justify-start items-start gap-[10px]">
                        <img className="mt-[7px] w-[20px]" src={alarm} alt="" />
                        <div>
                          <p className="bText-primary-d font-medium text-[24px]">
                            {getTime.getFullTimeString(item.notifyTime)}
                          </p>
                          <p className="bText-gray text-[14px]">
                            วันที่นัดพบคุณหมอ:{" "}
                            {getTime.getFullTimeString(item.notifyTime)}
                          </p>
                        </div>
                      </div>

                      <CustomSwitch
                        checked={item.isNoti}
                        handleFunction={() => {
                          postUpdatePetNoti(item.petId, !item.isNoti);
                        }}
                      />
                    </div>

                    <button
                      onClick={() => navigate("/dogbook-edit-noti")}
                      className="mt-[20px] flex justify-center items-center bg-lightBlue2 rounded-[28px] w-full gap-[10px] py-[8px]"
                    >
                      <img className="w-[20px]" src={alarm} alt="" />
                      <p className="text-[24px] bText-primary-d text-center">
                        ตั้งเวลาแจ้งเตือนพบคุณหมอ
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    );
  };

  const onInvite = (e) => {
    // console.log("e >>", e.target.id);
    // console.log("e >>", e.target.className);
    // console.log("e >>", e.target);
    navigate("/spectra-near-you-main");
    // dispatch(setStampInviteFriend({ memberCode: ownerProfile.memberCode }));
    // if (e.target.id === "tooltipExit") {
    //   setOpenTooltip(false);
    //   localStorage.setItem("show-tooltip ", "1");
    // } else if (e.target.className === "bBtnInvite") {

    // }
  };

  useEffect(() => {
    let showTooltip = localStorage.getItem("show-tooltip ");
    console.log("showTooltip >>", showTooltip);
    if (showTooltip) {
      setOpenTooltip(false);
    } else {
      setOpenTooltip(true);
    }
  }, []);

  return (
    <div id="PageDogBook">
      <CookieSettingPopup
        open={openCookiePopup}
        onClose={() => {
          setOpenCookiePopup(false);
        }}
      />
      <div id="dogbook-page">
        <TopLogo />
        <TopBanner type="dogbook" />
        <div id="dogbook" className="bgPrimaryGD mt-[-20px] px-[20px]">
          <div className="relative">
            <MemberCard data={ownerProfile} showTierBar={false} />

            {/* <div id="inviteFriendBanner" onClick={onInvite}>
              <p className="tInvite">
                ใช้สิทธิแลกคูปอง
                <br />
                สำหรับลูกค้า
              </p>
              <button className="bBtnInvite">
                <i className="bBtnInvite"></i>
                <p className="bBtnInvite">ใช้คูปอง</p>
              </button>
            </div> */}

            <div className="bg-white rounded-[4px] mt-[24px]">
              <div className="flex items-center">
                <p
                  onClick={() => setPage("info")}
                  className={
                    `w-full text-[20px] bText-gray text-center py-[9px] border-b-[1px] border-lightGray ` +
                    (page === "info" &&
                      "!bText-primary-d !border-b-[4px] !border-blue")
                  }
                >
                  ข้อมูลผู้ใช้
                </p>
                <p
                  onClick={() => setPage("address")}
                  className={
                    `w-full text-[20px] bText-gray text-center py-[9px] border-b-[1px] border-lightGray ` +
                    (page === "address" &&
                      "!bText-primary-d !border-b-[4px] !border-blue")
                  }
                >
                  สมุดที่อยู่
                </p>
              </div>

              {page === "info" && ownerProfile ? (
                <div className="p-[16px]">
                  <p className="text-[24px] text-lightBlue">ข้อมูลผู้ใช้</p>
                  <div className="flex flex-col gap-[8px] mt-[10px]">
                    {UserInfo("รหัสสมาชิก", ownerProfile.memberCode)}
                    {UserInfo(
                      "ชื่อ - นามสกุล",
                      `${ownerProfile.firstName} ${ownerProfile.lastName}`
                    )}
                    {UserInfo("อีเมล", ownerProfile.email)}
                    {UserInfo("เบอร์โทรศัพท์", ownerProfile.mobileNumber)}

                    {UserInfo(
                      "โรงพยาบาลสัตว์/\nคลินิกประจำ",
                      ownerProfile.favoriteHospital?.shopName ?? "-"
                    )}

                    {UserInfo(
                      "ร้านเพ็ทช็อปประจำ",
                      ownerProfile.favoriteShop?.shopName ?? "-"
                    )}

                    {UserInfo(
                      "ที่บ้านเลี้ยงสุนัขหรือไม่",
                      ownerProfile.isHaveCat ? "เลี้ยง" : "ไม่เลี้ยง"
                    )}
                  </div>

                  <button
                    onClick={() => navigate("/dogbook-edit-info")}
                    className="mt-[20px] w-fit flex gap-[5px] items-center justify-center py-[2px] px-[24px] rounded-[28px] border-[1px] border-blue"
                  >
                    <img className="w-[14px]" src={edit} alt="" />
                    <p className="bText-primary-d text-[20px]">แก้ไขข้อมูล</p>
                  </button>
                </div>
              ) : (
                <div className="p-[16px]">
                  <p className="text-[24px] text-lightBlue">สมุดที่อยู่</p>

                  {ownerProfile && ownerProfile.address ? (
                    <div>
                      <div className="flex flex-col gap-[12px]">
                        <div>
                          <p className="text-[20px] bText-gray">ที่อยู่:</p>
                          <p className="text-[20px]">
                            {ownerProfile.addressDetail}{" "}
                            {ownerProfile.address.addressName}
                          </p>
                        </div>

                        {ownerProfile.deAddress && (
                          <div>
                            <p className="text-[20px] bText-gray">
                              ที่อยู่จัดส่ง:
                            </p>
                            <p className="text-[20px]">
                              {ownerProfile.deAddressDetail}{" "}
                              {ownerProfile.deAddress.addressName}
                            </p>
                          </div>
                        )}
                      </div>

                      <button
                        onClick={() => navigate("/dogbook-edit-info")}
                        className="mt-[20px] w-fit flex gap-[5px] items-center justify-center py-[2px] px-[24px] rounded-[28px] border-[1px] border-blue"
                      >
                        <img className="w-[14px]" src={edit} alt="" />
                        <p className="bText-primary-d text-[20px]">แก้ไขข้อมูล</p>
                      </button>
                    </div>
                  ) : (
                    <div>
                      <p className="text-[20px]">
                        กรอกข้อมูลที่อยู่ของคุณเพื่อใช้ในการรับของรางวัล และรับ
                        สิทธิพิเศษต่างๆจาก NEX
                      </p>

                      <button
                        onClick={() => navigate("/dogbook-edit-info")}
                        className="mt-[20px] w-fit flex gap-[5px] items-center justify-center py-[2px] px-[24px] rounded-[28px] border-[1px] border-blue"
                      >
                        <img className="w-[10px]" src={add} alt="" />
                        <p className="bText-primary-d text-[20px]">
                          เพิ่มข้อมูลที่อยู่
                        </p>
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
            {/* <div className="flex justify-center">
              <button
                className="w-[90%] mt-[24px] text-white text-[24px] text-center py-[9px] px-[24px] bg-blue/[0.24] rounded-[28px]"
                onClick={() => navigate("/my-point-history-owner")}
              >
                ประวัติการใช้คูปอง
              </button>
            </div> */}
            <div className="flex justify-center">

              <button
                className="w-[90%] mt-[15px] mb-[24px] text-white text-[24px] text-center py-[9px] px-[24px] bg-blue/[0.24] rounded-[28px]"
                onClick={() => setOpenCookiePopup(true)}
              >
                ตั้งค่านโยบายความเป็นส่วนตัวและคุกกี้
              </button>
            </div>
          </div>
        </div>

        <div className="mt-[20px] mb-[36px] px-[20px]">
          <p className="text-lightBlue text-[28px] text-left">สมุดพกน้องแมว</p>
          {pet ? (
            <PetCarousal />
          ) : (
            <div className="mt-[10px] flex justify-start items-start gap-[10px]">
              <div>
                <DogPaw width={17} color="#00B960" />
              </div>
              <p className="text-[20px] text-left leading-[20px]">
                กรุณาเพิ่มข้อมูลน้องแมวของคุณ เพื่อรับสิทธิพิเศษต่างๆ ของน้องแมว
                โดยกดที่ปุ่ม ‘เพ่ิมข้อมูลน้องแมว’ ด้านล่าง
              </p>
            </div>
          )}
        </div>

        <div
          id="add-btn"
          className="flex flex-col justify-center bg-white px-[20px] pt-[16px] pb-[24px]"
        >
          <button
            onClick={() => navigate("/dogbook-add")}
            className="flex justify-center items-center bg-blue rounded-[28px] w-full gap-[10px] py-[8px]"
          >
            <DogPaw width={20} color="#FFFFFF" />
            <p className="text-[24px] text-white text-center">
              เพิ่มข้อมูลน้องแมว
            </p>
          </button>
        </div>
      </div>
    </div>
  );
}


